import './barHorizontal.scss'

const BarHorizontal = (props) => {
    return(
        <div className='containBarHorizontal'
        style={{
            justifyContent: props.toRight ? 'flex-start' : 'flex-end'
        }}>
            {
                props.toRight ? 
                <>
                    <div className='barHorizontal'></div>
                    <div className='dotBar'></div>
                </>
                :
                <>
                    <div className='dotBar'></div>
                    <div className='barHorizontal'></div>
                </>
            }
        </div>
    )
}

export default BarHorizontal