import './whoAreWe.scss'

const data  = [
    'Un ESN (Entreprise de Service Numérique)',
    'Un TEAM de jeunes ambitieux, challenger et surtout plus humain que jamais malgré nos différentes spécialités',
    'Des ressources précieuses, nécessaires pour bien répondre à votre besoin',
    'Des artisans qui font de votre rêve une idée et votre idée en projet puis votre projet en réalité'
]

const WhoAreWe = () => {

    return (
        <div className='mainPadding whoAreWeContainer'>
            <div className='mainTitle'>
                Qui sommes nous ?
            </div>

            {
                data.map((item, index) => {
                    return (
                        <div key={index} className='listShow listTarget containList'
                        data-aos='fade-right' data-aos-anchor-placement="top-bottom"
                        data-aos-duration={1000*(index+2)}>
                            <p>-</p>
                            <div>{item}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WhoAreWe