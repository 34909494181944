import './header.scss'
import logo from './../../assets/images/logoIzara.png'
import React from 'react'

const Header = () => {

    return (
        <div className='mainHeader' data-aos="fade-up" data-aos-duration="1000">
            <img src={logo} alt='izaraAgency' className='logo' />
            <div className='bottomLogo'></div>

            <div className='primaryTitle' data-aos="fade-right" data-aos-duration="500">
                Ciblons ENSEMBLES
            </div>

            <div className='secondaryTitle' data-aos="fade-right" data-aos-duration="1300">
                vos objectifs
            </div>

            <div className='description' data-aos="fade-right" data-aos-duration="2000">
                On est une équipe qui met en avant l'humain 
                et qui partage la même vision.
            </div>

            <div className='contact' data-aos="zoom-out" data-aos-duration="400">
                contact@izaragency.com
            </div>
        </div>
    )
}

export default Header