import './tarif.scss'
import { Container, Row, Col } from 'react-bootstrap'
import mobile from './../../assets/images/application.png'
import depannage from './../../assets/images/depannage.png'
import vitrine from './../../assets/images/vitrine.png'
import webapp from './../../assets/images/webapp.jpg'


const listTarif = [
    {
        icon: vitrine,
        title: 'Site vitrine',
        montant: '345€ (5j)',
        list: [
            'Site clé en main contenant 4 pages ou one page avec un formulaire de contact.'
        ]
    },
    {
        icon: webapp,
        title: 'Application web',
        montant: 'à partir de 9€ / Heure*',
        list: [
            'Designer',
            'Dév. Front End',
            'Dev. Back End',
            'Référenceur',
            'Chef de projet …'
        ]
    },
    {
        icon: mobile,
        title: 'Application mobile',
        montant: 'Sur devis',
        list: [
            'Designer',
            'Dév. Front End',
            'Dev. Back End',
            'Testeur',
            'Chef de projet …'
        ]
    },
    {
        icon: depannage,
        title: 'Autres profils',
        montant: '5€ / Heure*',
        list: [
            'Ass. virtuel',
            'Rédacteur',
            'Transcripteur',
            'Comm. Manager '
        ]
    }
]

const Tarif = () => {
    return (
        <div className='tarifContainer'>
            <div className='partOneTarif'>
                <div className='popInfoTarif'>
                    *Tarif par profil
                </div>

                <div className='firstTitleTarif'>
                    Nos Tarifs
                </div>

                <div className='secondTitleTarif'>
                    Choisissez vous-même le nombre de vos profils selon votre besoin !
                </div>
            </div>

            <div className='partTwoTarif'>
                <div className='listTarif'>
                    <Container>
                        <Row>
                        {
                            listTarif.map((item, index) => {
                            return (
                                <Col key={index+'P'} data-aos='fade-up' data-aos-duration="1000">
                                    <div className='itemTarif'>
                                        <div className='containIconTarif'>
                                            <img src={item.icon} alt='icon'/>
                                        </div>
                                        <div className='titleTarif' data-aos='fade-left' data-aos-duration="1000">
                                                {item.title}
                                        </div>
                                        <div className='montantTarif' data-aos='fade-left' data-aos-duration="1200">
                                            {item.montant}
                                        </div>
                                        <div className='detailListTarif' data-aos='fade-left' data-aos-duration="1300"> 
                                            {
                                                item.list.map((text, indice) => {
                                                    return (
                                                        <div key={indice+'V'}>
                                                            - {text}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </Col>
                            ) 
                            })
                        }
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Tarif