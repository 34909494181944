import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import 'aos/dist/aos.css';
import Header from './components/header/header';
import WhoAreWe from './components/who-are-we/whoAreWe';
import Evolution from './components/evolution/evolution';
import Constatation from './components/constatation/constatation';
import Solutions from './components/solutions/solutions';
import Tarif from './components/tarif/tarif';
import Techno from './components/techno/techno';
import How from './components/how/how';
import Thanks from './components/thanks/thanks';
import AOS from 'aos';
import React, { useEffect, Fragment, useState } from 'react';
import bg from './assets/images/521111.jpg';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import MainPage from './components/mainPage/mainPage';
import CheckPoint from './components/checkPoint/checkPoint';
import Cookie from './components/cookie/cookie';

const component = [
  {
    page: <Header/>
  },
  {
    page: <WhoAreWe/>
  },
  {
    page: <Evolution/>
  },
  {
    page: <Constatation/>
  },
  {
    page: <Solutions/>
  },
  {
    page: <Tarif/>
  },
  {
    page: <Techno/>
  },
  {
    page: <How/>
  },
  {
    page: <Thanks/>
  }
]

function App() {
  const [hideCookie, setHideCookie] = useState(true)

  useEffect(() => {    
      AOS.init({
        once: true
      })
      setTimeout(() => {
        setHideCookie(false)
      }, 5000)
  }, [])

  const closeCookie = () => {
    setHideCookie(true)
  }

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route exact path="/" element={<MainPage/>} />
          <Route exact path="/rendez-vous" element={<CheckPoint/>} />
        </Routes>
      </Fragment>
      {
        !hideCookie &&         
        <Cookie close={() => closeCookie()}/>
      }
    </Router>
  )

}

export default App;
