import './barTop.scss'

const BarTop = (props) => {
    return(
        <div className='containBarTop'>
            {
                props.toBottom ? 
                <>
                    <div className='barTop'></div>
                    <div className='dotBar'></div>
                </>
                :
                <>
                    <div className='dotBar'></div>
                    <div className='barTop'></div>
                </>
            }
        </div>
    )
}

export default BarTop