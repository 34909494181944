import { Col, Container, Row } from 'react-bootstrap'
import './how.scss'

const procedure = [
    'Fixons un rendez-vous pour parler de votre idée.',
    'Discutons ensemble de ce qui pourrait être nécessaire pour que ça se déroule parfaitement.',
    'Pas besoins de payer en avance !',
    'On vous accompagnera tout au long du projet, on vera des points à chaque avancement.',
    'Une fois que tout sera terminé, on vera un débrief et pourquoi pas ne pas parler de la suite de notre collaboration ?'
]

const Line = ({height}) => {
    return (
        <div style={{height: height}} className='lineHow'></div>
    )
}

const How = () => {
    return (
        <div className='howContainer'>
            <Line height={5} />
            <Container fluid>
                <Row md={12}>
                    <Col md={6}>
                        <div className='leftHowDivider'>
                            <p>
                                Comment on fait alors !
                            </p>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className='rightHowDivider'>
                        {
                            procedure.map((item, index) => {
                                return (
                                    <div className='containList itemProcedure' key={index}
                                    data-aos='fade-right' data-aos-anchor-placement="top-bottom"
                                    data-aos-duration={1000*(index+0.5)}>
                                        <p>-</p>
                                        <div>{item}</div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Line height={2} />
        </div>
    )
}

export default How