import React, { useEffect, useState } from 'react'
import './mainPage.scss'
import Header from './../header/header';
import WhoAreWe from './../who-are-we/whoAreWe';
import Evolution from './../evolution/evolution';
import Constatation from './../constatation/constatation';
import Solutions from './../solutions/solutions';
import Tarif from './../tarif/tarif';
import Techno from './../techno/techno';
import How from './../how/how';
import Thanks from './../thanks/thanks';
import bg from './../../assets/images/521111.jpg';
import calendar from './../../assets/images/calendar.png'
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';

const component = [
  {
    page: <Header/>
  },
  {
    page: <WhoAreWe/>
  },
  {
    page: <Evolution/>
  },
  {
    page: <Constatation/>
  },
  {
    page: <Solutions/>
  },
  {
    page: <Tarif/>
  },
  {
    page: <Techno/>
  },
  {
    page: <How/>
  },
  {
    page: <Thanks/>
  }
]

const MainPage = () => {
    const navigate = useNavigate();
    const [hidePopup, setHidePopup] = useState(false)

    useEffect(() => {    
        AOS.init({
          once: true
        })
        // document.getElementById('mainContainerCheckpoint').style.display = 'none'
        // setTimeout(() => {
        //   document.getElementById('mainContainerCheckpoint').style.display = 'block'
        // }, 1500)
    }, [])

    return (
        <React.Fragment>    
        <img src={bg} alt='bg' className='bgScreen'/>
  
        <div className='mainApp'>
            <div className='containTitle'>
                <div className='humTitle'>
                    hum hum !
                </div>
            </div>
  
            {
              component.map((item, index) => {
                return (
                <div key={index}>
                  {item.page}
                  <div className='separatorPage'></div>
                </div>
                )
              })
            }
        </div>

        {
          !hidePopup &&
          <div className='mainContainerCheckpoint'>
            <div className='containerCheckpoint' data-aos='fade-left' data-aos-duration='1500'>
                <img src={calendar} alt='calendar' />
                <p onClick={() => navigate('/rendez-vous')}>
                  Proposer un rendez-vous
                </p>
                <span className='closePopup' onClick={() => setHidePopup(true)}>
                  x
                </span>
            </div>
          </div>
        }

      </React.Fragment>
    )
}

export default MainPage