import { Col, Container, Row } from 'react-bootstrap'
import './thanks.scss'

const Thanks = () => {
    return (
        <div className='thanksContainer'>
            <div className='barThanks'></div>
            <div className='thanksDivider'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='leftDivider'>
                                <div>
                                    Merci !
                                </div>
                                <div>
                                    Trêve de blabla, vous avez des 
                                    questions ?
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='rightDivider'>
                                <div className='boxThanks one' 
                                data-aos='zoom-out' data-aos-duration='1000' >
                                    Rêve
                                </div>
                                <div className='verticalBar'></div>
                                <div className='boxThanks two'
                                data-aos='zoom-out' data-aos-duration='1200'>
                                    Idée / Projet
                                </div>
                                <div className='verticalBar'></div>
                                <div className='boxThanks three'
                                data-aos='zoom-out' data-aos-duration='1400'>
                                    Réalité
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>


            </div>
        </div>
    )
}

export default Thanks