import { Col, Container, Row } from 'react-bootstrap'
import './solutions.scss'

const listSolution = [
    'On vous met à disposition une équipe sur-mesure pour vos besoins.',
    'Pas besoins de dépenser des sommes énormes pour un projet de qualité, seul le travail effectué devrait être facturé.',
    'Un échange sur-mesure avec des étapes plus simplifiées en mettant en avant le contact humain.',
    'Un suivi et accompagnement de A à Z pour répondre totalement à vos attentes.'
]

const Solutions = () => {
    return (
        <div className='mainSolution'>
            <Container fluid>
                <Row xl={12}>
                    <Col xl={6} style={{padding: 0}}>
                        <div className='leftSolution'>
                            <div data-aos='zoom-in'>
                                Nos solutions
                            </div>
                        </div>
                    </Col>

                    <Col xl={6} style={{padding: 0}}>
                        <div className='rightSolution'>
                            {
                                listSolution.map((item, index) => {
                                    return (
                                        <div key={index} className='listShow containList'>
                                            <p>-</p>
                                            <div>{item}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className='lineSolution'></div>
                        </div>
                    </Col>
                </Row>
            </Container>



        </div>
    )
}

export default Solutions