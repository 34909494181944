import './checkPoint.scss'
import Calendar from 'react-awesome-calendar'
import React, { useEffect, useState } from 'react';
import bg from './../../assets/images/521111.jpg';
import logo from './../../assets/images/logoIzara.png'
import clock from './../../assets/images/clock.png'
import calendar from './../../assets/images/calendar.png'
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'

const CheckPoint = () => {
    const navigation = useNavigate()
    let calendarRef = React.createRef()
    const [timeZone, setTimeZone] = useState()
    const [validDate, setValidDate] = useState(false)
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [fullDate, setFullDate] = useState();
    const [hour, setHour] = useState();
    const [email, setEmail] = useState();
    const [error, setError] = useState();

    const handleClose = () => {
        setShow(false)
        setError(null)
    }

    const handleShow = () => setShow(true);

    const confirm = () => {
        setError(null)
        var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
        if (email && email.match(pattern)) {
            setShow(false)
            navigation('/')
        } else {
            setError('Email invalide')
        }
    };

    useEffect(() => {
        const current = new Date()
        let GMT = current.toTimeString().substring(12, 15)
        setTimeZone(parseInt(GMT))
    }, [])

    let events = [{
        id: 1,
        color: '#f46524',
        from: '2022-03-01T13:00:00+00:00',
        to: '2022-03-01T13:00:00+00:00',
        title: 'Reservé',
        year: 2022,
        month: 3,
        day: 1,
        hour: 13
    }, {
        id: 2,
        color: '#f46524',
        from: 'Mon, 28 Feb 2022 21:00:00 GMT',
        to: 'Mon, 28 Feb 2022 21:00:00 GMT',
        title: 'Reservé',
        year: 2022,
        month: 2,
        day: 28,
        hour: 21
    }, {
        id: 3,
        color: '#f46524',
        from: '2022-03-10T08:00:00.000Z',
        to: '2022-03-10T08:00:00.000Z',
        title: 'Reservé',
        year: 2022,
        month: 3,
        day: 10,
        hour: 8
    }];

    const [checkpoint, setCheckpoint] = useState(events)

    const changeTimeLine = async (time) => {
        let exist = false;

        await events.forEach((item) => {
            if (item.year === time.year && item.month === time.month+1 && item.day === time.day && item.hour === time.hour) {
                exist = true;
            } 
        })

        let data = new Date(time.year, time.month, time.day, time.hour)
    
        data = new Date(time.year, time.month, time.day, time.hour + timeZone)

        let newReservation = {}

        if (new Date().getTime() > data.getTime()) {
            newReservation = {
                id: events.length + 1,
                color: 'rgb(255 0 0)',
                from: data.toISOString(),
                to: data.toISOString(),
                title: 'Date expirée',
                year: time.year,
                month: time.month,
                day: time.day,
                hour: time.hour
            }
            setValidDate(false)
        } else {
            newReservation = {
                id: events.length + 1,
                color: '#4bc',
                from: data.toISOString(),
                to: data.toISOString(),
                title: 'Votre rendez-vous',
                year: time.year,
                month: time.month,
                day: time.day,
                hour: time.hour
            }
            setFullDate(data.toUTCString())
            setDate(data.toLocaleDateString())
            const hour = parseInt(data.getUTCHours().toLocaleString())
            const newHour = hour < 10 ? `0${hour}` : `${hour}`
            setHour(`${newHour}:00`)    
            setValidDate(true)
        }

        if (!exist) {
            events.push(newReservation)
            setCheckpoint(events)
        }
    }

    return (
        <div className='containCheckPoint'>
            <img src={bg} alt='bg' className='bgScreen'/>
            
            <Container fluid>
                <Row md={12}>
                    <Col md={6}>
                        <div className='containLeftCalendar'>
                            <div>
                                <img src={logo} alt='logoIzar' className='logo' onClick={() => navigation('/')}/>
                                <div className='underline'></div>
                            </div>

                            <div className='titleCheckpoint'>
                                Demande de rendez-vous téléphonique 
                                <span> - FR </span>
                            </div>

                            <p className='descriptionCheckpoint'>
                                Sélectionnez la date et l' heure de votre rendez-vous. <br/>
                                Planifiez un appel avec un chef de projet 
                                afin d'échanger votre projet .
                            </p>

                            <div className='containDuration'> 
                                <img src={clock} alt='clock' className='clock'/>
                                <p> 15 min </p>
                                <span> - durée par defaut</span>
                            </div>

                            <div>
                                <p className='txtTimezone'>Votre fuseau horaire : </p>
                                <select className='select'>
                                    <option>
                                        {new Date().toTimeString().substring(8,)}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className='containerCalendar'>
                            <Calendar events={checkpoint} 
                            onClickTimeLine={(time) => changeTimeLine(time)}
                            ref={calendarRef}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

            {
                validDate && 
                <div className='mainBtnValidate'>
                    <div className='containBtnValidate'>
                        <img src={calendar} alt='calendar'/>
                        <button className='btnValidate' onClick={handleShow}>
                            Valider la date
                        </button>
                    </div>
                </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        <div>
                            Confirmation
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='bodyPopup'>
                        <div className='mainBody'>
                            Vous avez reservé un rendez-vous avec l'équipe experte <span>IZAR Agency</span>
                        </div>
                        <input placeholder='Votre email de confirmation' 
                        onChange={(event) => {
                            setEmail(event.target.value)
                            var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
                            if (email && email.match(pattern)) {
                                setError(null)
                            } else {
                                setError('Email invalide')
                            }                    
                        }}
                        style={{marginBottom:'10px' ,borderColor: error ? 'red' : 'transparent', borderWidth: error ? 2 : 0}}/>

                        <div>
                            Ce sera le {date} à {hour}
                        </div>
                        {/* <p>
                            {fullDate}
                        </p> */}
                        <p>
                        Une confirmation du rendez-vous sera envoyée par mail à votre adresse,
                            <span>merci de vérifier dans vos spams.</span>
                        </p>
                        {/* {
                            error && <p className='error'>{error}</p>
                        } */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <Button onClick={() => confirm()} className='acceptBtn'>
                    <div>
                        Confirmer
                    </div>
                </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default CheckPoint