import imgCookie from './../../assets/images/cookies.png'
import close from './../../assets/images/close.png'
import './cookie.scss'

const Cookie = (props) => {
    return (
        <div className='mainCookie'>
            <img src={imgCookie} alt='cookie' className='imgCookie'/>
            <img src={close} alt='close' className='close' onClick={() => {
                if (props.close) props.close()
            }}/>
            <div className='titleCookie'>
                Ce site utilise de Cookies
            </div>
            <p>
            Ce site utilise des cookies pour améliorer l'expérience utilisateur. 
            En utilisant notre site Web, vous consentez à tous les cookies conformément 
            à notre politique en matière de cookies.
            </p>
            <div className='acceptCookie' onClick={() => {
                if (props.close) props.close()
            }}>
                Tout accepter
            </div>
        </div>
    )
}

export default Cookie