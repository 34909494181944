import './constatation.scss'

const listConstat = [
    'Des tarifs de plus en plus inaccessible pour une bonne qualité de travail',
    'Des étapes automatisés qui remplace petit à petit le contact humain',
    'Beaucoup de blabla, mais peu \n d\'écoute des vrais besoins',
    '...'
]

const barConstat = [
    {
        title: 'Accessibilité des tarifs',
        height: 80,
        color: 'rgb(247 225 163)'
    },
    {
        title: 'Relation humaine',
        height: 120,
        color: 'rgb(239 190 100)'
    },
    {
        title: 'Les blabla',
        height: 300,
        color: 'rgb(255 189 69)'
    },
    {
        title: 'Difficultés des étapes',
        height: 250,
        color: 'rgb(245 167 24)'
    }
]

const Constatation = () => {
    return (
        <div className='mainPadding constatationContainer'>
            <div className='titleConstatation'>
                Ce qu'on constate malheureusement
            </div>
            
            {
                listConstat.map((item, index) => {
                    return (
                        <div key={index} className='listShow listTarget containList'
                        data-aos='fade-right' data-aos-anchor-placement="top-bottom"
                        data-aos-duration={1000*(index+0.5)}>
                            <p>-</p>
                            <div>{item}</div>
                        </div>
                    )
                })
            }

            <div className='containBarConstat' data-aos='fade-left'>
                <div className='listBarConstat'>
                    {
                        barConstat.map((item, index) => {
                            return (
                                <div key={index+'B'} className='containDetailBarConstat'>
                                    <div className='barConstat' 
                                    style={{height: item.height, backgroundColor: item.color}}>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='containDetailBarConstat'>
                        <div className='barConstatHided' 
                            style={{height: 350, backgroundColor: 'transparent', width: 1}}>
                        </div>
                    </div>
                </div>
                <div className='dotBarConstat'></div>
                <div className='listBarConstat containerTitleBar'>
                    {
                        barConstat.map((item, index) => {
                            return (
                                <div key={index+'C'} className='containDetailBarConstat'>
                                    <div className='titleBar' style={{color: item.color}}>
                                        {item.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='containDetailBarConstat'>
                        <div className='titleBarHided' 
                            style={{color: 'transparent', width: 1}}>
                                .
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Constatation