import BarHorizontal from './barHorizontal/barHorizontal'
import BarTop from './barTop/barTop'
import './evolution.scss'

const dataTop = [
    {
        title: 'Démarrage',
        description: 'Quelques clients locaux'
    },
    {
        title: 'Perfectionnement',
        description: 'Amélioration des services et tarifs'
    },
    {
        title: 'Nouvel Stratégie',
        description: 'Partenariat avec des entreprises depuis la France'
    }
]

const dataBottom = [
    {
        title: 'Ciblage Ext.',
        description: 'Quelques projets depuis la France'
    },
    {
        title: 'Dev. Marcher',
        description: 'Plusieurs projets depuis la France et la Réunion'
    }
]

const Evolution = () => {
    return (
        <div className='mainPadding evolutionContainer'>
            <div className='topBar'>
                <div className='topBarOne'></div>
                <div className='topBarTwo'></div>
            </div>
            <div className='titleEvolution' data-aos="zoom-in">
                Notre évolution
            </div>
            <div className='underTitleEvolution' data-aos="zoom-in">
                Pour vous expliquer en quelques étapes
            </div>

            <div className='containEvolution' data-aos="zoom-in" data-aos-duration='1500'>
                <div className='firstItemEvolution'>
                    {
                        dataTop.map((item, index) => {
                            return (
                            <div key={index} className='itemEvolution'>
                                <BarTop/>
                                <div className='detailItemEvolution'>
                                <div className='titleItemEvolution'>
                                    {item.title}
                                </div>
                                <div className='descriptionItemEvolution'>
                                    {item.description}
                                </div>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>

                <div className='secondItemEvolution'>
                    <p>2020</p>
                    <p>2021</p>
                    <p>2022</p>
                </div>

                <div className='thirdItemEvolution'>
                    {
                        dataBottom.map((item, index) => {
                            return (
                                <div key={index+'A'} className='itemEvolution'>
                                    <BarTop toBottom={true} />
                                    <div className='detailItemEvolution'>
                                    <div className='titleItemEvolution'>
                                        {item.title}
                                    </div>
                                    <div className='descriptionItemEvolution'>
                                        {item.description}
                                    </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='containEvolutionMobile'>
                <div className='containEvolutionLeftMobile'>
                    {
                        dataBottom.map((item, index) => {
                            return (
                                <div key={index+'D'} className='itemEvolution'>
                                    <BarHorizontal/>
                                    <div className='detailItemEvolution'>
                                    <div className='titleItemEvolution'>
                                        {item.title}
                                    </div>
                                    <div className='descriptionItemEvolution'>
                                        {item.description}
                                    </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='containYearMobile'>
                    <p>2020</p>
                    <p>2021</p>
                    <p>2022</p>
                </div>

                <div className='containEvolutionRightMobile'>
                    {
                        dataTop.map((item, index) => {
                            return (
                            <div key={index+'H'} className='itemEvolution'>
                                <BarHorizontal toRight={true} />
                                <div className='detailItemEvolution'>
                                <div className='titleItemEvolution'>
                                    {item.title}
                                </div>
                                <div className='descriptionItemEvolution'>
                                    {item.description}
                                </div>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='containLastDivider'>
                <div className='lastDivider'></div>
            </div>
        </div>
    )
}   

export default Evolution