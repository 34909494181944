import './techno.scss'
import wordpress from './../../assets/images/wordpresscom5812.jpg'
import react from './../../assets/images/react.svg'
import codeIgniter from './../../assets/images/code_igniter.jpg'
import node from './../../assets/images/node2.png'
import laravel from './../../assets/images/laravel-1.svg'
import assist from './../../assets/images/virtual-Assist.png'
import symfony from './../../assets/images/symfony-logo-AA34C8FC16-seeklogo.com.png'
import { Container, Row, Col } from 'react-bootstrap'

const listTechno = [
    // {
    //     icon: wordpress,
    //     title: 'Wordpress (Builder Divi)',
    //     percent: '95%',
    //     width: 150,
    //     height: 150
    // },
    {
        icon: react,
        title: 'React JS/Native',
        percent: '95%',
        width: 150,
        height: 150
    },
    // {
    //     icon: codeIgniter,
    //     title: 'CodeIgniter',
    //     percent: '95%',
    //     width: 150,
    //     height: 100
    // },
    {
        icon: node,
        title: 'Node',
        percent: '95%',
        width: 200,
        height: 150
    },
    {
        icon: laravel,
        title: 'Laravel',
        percent: '90%',
        width: 150,
        height: 90
    },
    // {
    //     icon: assist,
    //     title: 'Assist. Virt, Rédaction, …',
    //     percent: '95%',
    //     width: 150,
    //     height: 150
    // },
    // {
    //     icon: symfony,
    //     title: 'Symfony',
    //     percent: '80%',
    //     width: 150,
    //     height: 100
    // }
]

const Techno = () =>  {
    return (
        <div className='technoContainer'>
            <div className='titleTechno' data-aos='fade-right' data-aos-duration='1000'>
                Nos spécialités :
            </div>
            <p>Oui, on en n'a pas beaucoup mais on maîtrise ce qu'on fait!</p>
            <div className='listTechno'>
                <Container>
                    <Row>
                        {
                            listTechno.map((item, index) => {
                                return (
                                    <Col md={6}  key={index}>
                                        {
                                            index % 2 === 0 ? 
                                            <div className='itemTechno' data-aos='fade-right' data-aos-duration='1000'>
                                                <img src={item.icon} alt='icon'
                                                style={{width: item.width, height: item.height}}/>
                                                <div className='titleItemTechno'>
                                                    {item.title}
                                                </div>
                                                <div className='percentItemTechno'>
                                                    {item.percent}
                                                </div>
                                            </div>
                                            :
                                            <div className='itemTechno' data-aos='fade-left' data-aos-duration='1000'>
                                                <img src={item.icon} alt='icon'
                                                style={{width: item.width, height: item.height}}/>
                                                <div className='titleItemTechno'>
                                                    {item.title}
                                                </div>
                                                <div className='percentItemTechno'>
                                                    {item.percent}
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Techno
